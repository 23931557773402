// indexedDB.js
let db= null;;
export function openDB(dbName, storeName) {
    return new Promise((resolve, reject) => {
      if (db) {
        resolve(db);
        return;
      }
      const request = indexedDB.open(dbName, 1);
      
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, { keyPath: "id", autoIncrement: true });
        }
      };
  
      request.onsuccess = (event) => {
        db = event.target.result;
        console.log('Database opened successfully');
        resolve(db);
      };
  
      request.onerror = (event) => {
        console.error('Database failed to open:', event.target.error);
        reject(event.target.error);
      };
    });
  }
  
  export async  function addMessageToDB(db, storeName, message) {
    
    try {
      if (!db) {
        console.log('Database is not open. Opening now...');
       db = await openDB();
      }

    return new Promise((resolve, reject) => {
     
      if (!db) {
        reject(new Error('Database is still undefined'));
        return;
      }
      if (!db.objectStoreNames.contains(storeName)) {
        reject(new Error(`Object store "${storeName}" not found`));
        return;
      }

      const transaction = db.transaction(storeName, "readwrite");
      
      const store = transaction.objectStore(storeName);
      const request = store.add(message);
  
      request.onsuccess = () => {
        console.log('Message added to IndexedDB:', message);
        resolve(request.result);
      };
  
      request.onerror = (event) => {
        console.error('Failed to add message to IndexedDB:', event.target.error);
        reject(event.target.error);
      };
    });
  }catch (error) {
    console.error('Error in addMessageToDB:', error);
    throw error;
  }
};
  
  export function getMessagesFromDB(db, storeName) {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, "readonly");
      const store = transaction.objectStore(storeName);
      const request = store.getAll();
  
      request.onsuccess = (event) => {
        resolve(event.target.result);
      };
  
      request.onerror = (event) => {
        reject(event.target.errorCode);
      };
    });
  }
  
  export function deleteMessageFromDB(db, storeName, id) {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, "readwrite");
      const store = transaction.objectStore(storeName);
      const request = store.delete(id);
  
      request.onsuccess = () => {
        resolve();
      };
  
      request.onerror = (event) => {
        console.log('onerrorrrrrr')
        reject(event.target.errorCode);
      };
    });
  }
  