import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function WhatsAppClockIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-.5 5h1v6l5.25 3.15-.5.85L11 14V7z" />
    </SvgIcon>
  );
}

export default WhatsAppClockIcon;
