import React from "react";
import "./chatCard.css";
import App1 from "chatting_screen_sdk";
const ChatCard = () => {
  const [open, setOpen] = React.useState(false);
  const [selectedlang, setSelectedLang] = React.useState("en");
  const [roomData, setRoomData] = React.useState("");

  const roomData1 = {   
    appKey: "12345",
        receiverDetails: {
          userName: "Alex Carter",
          countryCode: "1",
          mobileNumber: "09876511121",
          userId: "5000",
          email: "Carter@example.com",
          profileUrl: "https://res.cloudinary.com/dpiw7uxv9/image/upload/v1727164815/wefc5m2mpz7pb4mgndii.png",
        },

        senderDetails:  {   
          userName: "Ethan Brooks",
          countryCode: "1",
          mobileNumber: "1278787890",
          userId: "5001",
          email: "Brooks@example.com",
          profileUrl: "https://res.cloudinary.com/dpiw7uxv9/image/upload/v1727164815/wefc5m2mpz7pb4mgndii.png",
        },
  };

  const roomData2 = {   
    appKey: "12345",
    senderDetails: {
      userName: "Alex Carter",
      countryCode: "1",
      mobileNumber: "09876511121",
      userId: "5000",
      email: "Carter@example.com",
      profileUrl: "https://res.cloudinary.com/dpiw7uxv9/image/upload/v1727164815/wefc5m2mpz7pb4mgndii.png",
    },

    receiverDetails:  {   
      userName: "Ethan Brooks",
      countryCode: "1",
      mobileNumber: "1278787890",
      userId: "5001",
      email: "Brooks@example.com",
      profileUrl: "https://res.cloudinary.com/dpiw7uxv9/image/upload/v1727164815/wefc5m2mpz7pb4mgndii.png",
    },
  };

  return (
    <>
      {open ? (
        <App1 language={selectedlang} initiateChatRoomData={roomData} />
      ) : (
        <div style={{ display: "flex", alignItems: "center", height: "100vh" }}>
          <div className="chat-card">
            <div className="avatar">
              <img
                src={"/users (1).png"}
                // alt="Amit's Avatar"
              />
            </div>
            <div className="chat-info">
              <h2>Chat to Alex Carter</h2>
              <button
                className="chat-btn"
                onClick={() => {
                  setOpen(true);
                  setSelectedLang("en");
                  setRoomData(roomData1);
                }}
              >
                Start chat in english
              </button>
              <button
                className="chat-btn"
                onClick={() => {
                  setOpen(true);
                  setSelectedLang("ar");
                  setRoomData(roomData1);
                }}
              >
                Start chat in arabic
              </button>
            </div>
          </div>

          <div className="chat-card">
            <div className="avatar">
              <img
                src={"/download (8).jfif"}
                // alt="Amit's Avatar"
              />
            </div>
            <div className="chat-info">
              <h2>Chat to Ethan Brooks</h2>
              <button
                className="chat-btn"
                onClick={() => {
                  setOpen(true);   
                  setSelectedLang("en");
                  setRoomData(roomData2);
                }}
              >
                Start chat in english
              </button>
              <button
                className="chat-btn"
                onClick={() => {
                  setOpen(true);
                  setSelectedLang("ar");
                  setRoomData(roomData2);
                }}
              >
                Start chat in arabic
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatCard;
