import React from 'react';
import { Dialog, Box, Button } from '@mui/material';

export default function ExitGroupDialog({ exitGroupDialog, setExitGroupDialog, handleExistGroup, AppTheme }) {
  return (
    <Dialog
      open={exitGroupDialog}
      onClose={() => setExitGroupDialog(false)}
      PaperProps={{
        sx: {
          borderRadius: '10px', // Apply border radius
          backgroundColor: AppTheme === 'dark' ? '#202c33' : '#f0f2f5', // Background color based on theme
          padding: '20px', // Optional padding for a better look
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          color: AppTheme === 'light' ? '#111b21' : '#e9edef',
        }}
      >
        <Box sx={{ color: AppTheme === 'dark' ? '#ffffff' : '#000000', marginBottom: '20px' }}>
          Do you want to exit the group?
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '80%' }}>
          <Button onClick={() => setExitGroupDialog(false)}>No</Button>
          <Button sx={{ color: '#EA0038' }} onClick={handleExistGroup}>
            Yes
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
