import React, { useEffect, useState, useRef } from 'react';
import { FaMicrophone, FaMicrophoneSlash, FaVideo, FaVideoSlash, FaPhoneSlash, FaPhoneAlt } from 'react-icons/fa';
import './VideoChat.css'; // Custom CSS for WhatsApp-like UI

const VideoChat = ({ socketRef, chatRoomId, userId }) => {
  const socket = socketRef?.current;
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoOff, setIsVideoOff] = useState(false);
  const [callStarted, setCallStarted] = useState(false);
  const [isRinging, setIsRinging] = useState(false); // To handle incoming call ring
  const [isAudioCall, setIsAudioCall] = useState(false); // To toggle between audio and video calls
  const [callId, setCallId] = useState(null); // Store callId for later use

  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const peerConnectionRef = useRef(null);
  const iceCandidateQueue = useRef([]);

  const configuration = {
    iceServers: [{ urls: 'stun:stun.l.google.com:19302' }]
  };

  const getUserMedia = async (isAudioCall) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: !isAudioCall,
        audio: true
      });
      if (!isAudioCall) {
        localVideoRef.current.srcObject = stream; // Display video if it's a video call
      }
      setLocalStream(stream);
    } catch (error) {
      console.error('Error accessing media devices.', error);
    }
  };

  useEffect(() => {
    getUserMedia(isAudioCall); // Initial media access
    if (socket) {
      socket.on('offer', handleIncomingCall);
      socket.on('answer', handleAnswer);
      socket.on('ice-candidate', handleICECandidate);
      socket.on('callRejected', () => setIsRinging(false)); // Handle call rejection
    }
    return () => {
      if (socket) {
        socket.off('offer', handleIncomingCall);
        socket.off('answer', handleAnswer);
        socket.off('ice-candidate', handleICECandidate);
        socket.off('callRejected'); // Cleanup on unmount
      }
    };
  }, [isAudioCall]);

  const setupPeerConnection = () => {
    const peerConnection = new RTCPeerConnection(configuration);
    peerConnectionRef.current = peerConnection;

    if (localStream) {
      localStream.getTracks().forEach(track => peerConnection.addTrack(track, localStream));
    } else {
      console.error("Local stream not available. Unable to add tracks.");
    }

    peerConnection.ontrack = (event) => {
      setRemoteStream(event.streams[0]);
      if (!isAudioCall && remoteVideoRef.current) {
        remoteVideoRef.current.srcObject = event.streams[0];
      }
    };

    peerConnection.onicecandidate = (event) => {
      if (event.candidate) {
        socket.emit('ice-candidate', {
          candidate: event.candidate,
          chatRoomId: chatRoomId, 
          userId: userId, 
        });
      }
    };

    return peerConnection;
  };

  const handleIncomingCall = (data) => {
    const { offer, callId: incomingCallId } = data;
    setIsRinging(true);
    window.incomingOffer = offer; // Save the incoming offer
    setCallId(incomingCallId); // Set call ID for later use
  };

  const acceptCall = async () => {
    setIsRinging(false); // Stop ringing
    await getUserMedia(isAudioCall); // Get user media before accepting the call
    const peerConnection = setupPeerConnection();

    // Set the remote description to the saved incoming offer
    await peerConnection.setRemoteDescription(new RTCSessionDescription(window.incomingOffer));

    iceCandidateQueue.current.forEach(candidate => peerConnection.addIceCandidate(new RTCIceCandidate(candidate)));
    iceCandidateQueue.current = [];

    // Create and send answer
    const answer = await peerConnection.createAnswer();
    await peerConnection.setLocalDescription(answer);
    socket.emit('answer', {
      answer: peerConnection.localDescription,
      chatRoomId: chatRoomId,
      userId: userId,
      callId: callId
    });

    setCallStarted(true); // Mark call as started
  };

  const rejectCall = () => {
    setIsRinging(false); // Stop ringing
    socket.emit('callRejected'); // Inform the caller that the call was rejected
  };

  const handleAnswer = async (data) => {
    const { answer } = data;
    await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(answer));
  };

  const handleICECandidate = async (data) => {
    const { candidate } = data;
    if (peerConnectionRef.current) {
      if (peerConnectionRef.current.remoteDescription) {
        await peerConnectionRef.current.addIceCandidate(new RTCIceCandidate(candidate));
      } else {
        iceCandidateQueue.current.push(candidate);
      }
    }
  };

  const startCall = async () => {
    if (!localStream) {
      console.error('Local stream not available. Cannot start call.');
      return;
    }

    const peerConnection = setupPeerConnection();
    const offer = await peerConnection.createOffer();
    await peerConnection.setLocalDescription(offer);
    
    // Emit the offer event to the backend
    socket.emit('offer', {
      offer: peerConnection.localDescription,
      chatRoomId: chatRoomId, 
      userId: userId,
      callType: isAudioCall ? 'AUDIO' : 'VIDEO' 
    });
    setCallStarted(true); // Set call as started
  };

  const toggleMute = () => {
    if (localStream) {
      localStream.getAudioTracks()[0].enabled = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const toggleVideo = () => {
    if (localStream) {
      localStream.getVideoTracks()[0].enabled = !isVideoOff;
      setIsVideoOff(!isVideoOff);
    }
  };

  const endCall = () => {
    socket.emit('endCall');
    localStream.getTracks().forEach(track => track.stop()); // Stop local media tracks
    setLocalStream(null);
    setRemoteStream(null);
    setCallStarted(false);
    setIsRinging(false);
  };

  return (
    <div className="video-chat-container">
      <div className="remote-video-container">
        {!isAudioCall && <video ref={remoteVideoRef} autoPlay className="remote-video" />}
      </div>

      <div className="local-video-container">
        {!isAudioCall && <video ref={localVideoRef} autoPlay muted className="local-video" />}
      </div>

      {isRinging && (
        <div className="incoming-call-popup">
          <p>Incoming Call...</p>
          <button className="accept-button" onClick={acceptCall}>
            <FaPhoneAlt /> Accept
          </button>
          <button className="reject-button" onClick={rejectCall}>
            <FaPhoneSlash /> Reject
          </button>
        </div>
      )}

      {callStarted && (
        <div className="call-info">
          <p className="caller-name">John Doe</p>
          <p className="call-status">In Call...</p>
        </div>
      )}

      {callStarted && (
        <div className="call-controls">
          <button className="control-button" onClick={toggleMute}>
            {isMuted ? <FaMicrophoneSlash /> : <FaMicrophone />}
          </button>
          {!isAudioCall && (
            <button className="control-button" onClick={toggleVideo}>
              {isVideoOff ? <FaVideoSlash /> : <FaVideo />}
            </button>
          )}
          <button className="control-button hangup-button" onClick={endCall}>
            <FaPhoneSlash />
          </button>
        </div>
      )}

      {!callStarted && !isRinging && (
        <div className="start-call-container">
          <button className="start-call-button" onClick={() => { setIsAudioCall(false); startCall(); }}>
            Start Video Call
          </button>
          <button className="start-call-button" onClick={() => { setIsAudioCall(true); startCall(); }}>
            Start Audio Call
          </button>
        </div>
      )}
    </div>
  );
};

export default VideoChat;
