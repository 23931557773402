import React from 'react';
import ChatCard from './Components/ChatCard';
import './App.css';


function App() {
 
  return (
   
    <div className="App">
       <ChatCard/>
     </div>
   
  );
}

export default App;
