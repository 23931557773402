import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Avatar, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles"; // Import useTheme
import { useTranslation } from 'react-i18next';
const GroupInfoCard = ({ userId,profileImg,groupName, groupAvatar, createdBy, memberCount, description, onGroupInfoClick, onAddMembersClick, AppTheme, handleCreateGroup,handleOpenDialog,handlesetgroupInfoPage,participants }) => {
  const theme = useTheme(); // Get the current theme
  const { t } = useTranslation();
  const [adminName,setAdminName]= useState('')

  useEffect(()=>{
    console.log('oureouroiu',profileImg)
    if(participants.length>0){
      const adminUser= participants.find(member=> member.userRole==='ADMIN')
      adminUser.userId===userId ? setAdminName(''):setAdminName(adminUser.userName);
    }


},[profileImg,AppTheme,participants])

  return (
    <Paper
      elevation={2}
      sx={{
        boxSizing:'border-box',
        padding: "24px",
        margin: "16px",
        backgroundColor: AppTheme === "dark" ? "#202c33" : "#f0f2f5",
        color: AppTheme === "light" ? "#111b21" : "#e9edef",
        borderRadius: "20px",
        maxWidth: "600px", // Set a max-width for larger screens
        width: "60%", // Allow full width on smaller screens
        mx: "auto", // Center the card horizontally
        
        [theme.breakpoints.down("sm")]: { // Use the theme breakpoints
          padding: "16px",
          margin: "8px",
        },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        sx={{boxSizing:'border-box',width:'100%',lineHeight: "22px"}}
     
      >
        <Avatar
          src={profileImg}
          
          sx={{
            width: 80,
            height: 80,
            marginBottom: 2,
            [theme.breakpoints.down("sm")]: {
              width: 60,
              height: 60,
            },
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            boxSizing:'border-box',
            width:'100%'
          }}
        >
          
          <Typography
            sx={{
              fontSize: "19px",
              display:'flex',
              textAlign: "center",
              wordBreak: "break-word",
              
              [theme.breakpoints.down("sm")]: {
                fontSize: "16px",
              },
            }}
          >
              
            {t('createdGroup', { name: adminName || t('you')})}
          </Typography>
       
          <Typography
            sx={{
              fontSize: "13px",
              marginTop: 1,
              color: "#667781",
              textAlign: "center",
              [theme.breakpoints.down("sm")]: {
                fontSize: "12px",
              },
            }}
          >
          {t('groupMembers', { count: participants.length })}
          </Typography>
        </Box>
      </Box>
      <Typography
        sx={{
          marginTop: 1,
          marginBottom: 2,
          textAlign: "center",
          [theme.breakpoints.down("sm")]: {
            fontSize: "12px",
          },
        }}
      >
        {description}
      </Typography>
      <Box
        display="flex"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
          flexDirection: ["column", "row"], // Change layout direction on smaller screens
          [theme.breakpoints.down("sm")]: {
            gap: "10px",
          },
        }}
      >
        <Button
          sx={{
            border: "1px solid #667781",
            borderRadius: "20px",
            padding: "6px 16px",
            [theme.breakpoints.down("sm")]: {
              padding: "4px 12px",
              fontSize: "12px",
            },
          }}
          onClick={()=>handlesetgroupInfoPage(true)}
        >
         {t('groupInfo')}
        </Button>
        <Button
          sx={{
            border: "1px solid #667781",
            borderRadius: "20px",
            padding: "6px 16px",
            [theme.breakpoints.down("sm")]: {
              padding: "4px 12px",
              fontSize: "12px",
            },
          }}
          onClick={handleOpenDialog}
        >
         {t('addMembers')}
        </Button>
      </Box>
    </Paper>
  );
};

export default GroupInfoCard;
