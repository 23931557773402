import React, { useEffect, useState } from "react";
import { Box, IconButton, CircularProgress, Menu, MenuItem, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { IoIosDocument } from "react-icons/io";
import { MdPhotoLibrary } from "react-icons/md";
import { FaCamera } from "react-icons/fa";
import { useTranslation } from "react-i18next";
const MediaFile = ({
    mediaFileInput,
    AppTheme,
    handleSend,
    fileType,
    onClose,
    mediaType,
    fileSize,
    MediaPreview,
    setMediaFileInput,
    isFileSend,
    setIsFileSend

})=>{

  const { t, i18n } = useTranslation();
  // const [isFileSend,setIsFileSend] = useState(false)
  const isTypingInArabic = (text) => {
    if (text === "") return true;

    // Regex to detect Arabic characters including extended ranges
    const arabicRegex =
      /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB70-\uFBFF\uFE70-\uFEFF]/;
    return arabicRegex.test(text);
  };


    return (
        <Box
        sx={{
          position: "relative",
          top: "0px",
          background: AppTheme === "dark" ? "#101A20" : "#E9EDEF",
          height: "100%",
          width: "100%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >

        {/* <Box
          sx={{
            position: "absolute",
            top: 16,
            left: 16,
            display: "flex",
            width: "50px",
            backgroundColor: AppTheme === "dark" ? "#101A20 !important" : "#E9EDEF !important", // Initial background color
            "&:hover": {
              backgroundColor: AppTheme === "dark" ? "#101A20 !important" : "#E9EDEF !important",
            },
            "&:active": {
              backgroundColor: AppTheme === "dark" ? "#101A20 !important" : "#E9EDEF !important",
            },
            // whiteSpace: "nowrap",  // Ensure it stays on one line
            // overflow: "hidden",    // Hide overflow text
            // textOverflow: "ellipsis" // Add ellipsis
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{

              backgroundColor: AppTheme === "dark" ? "#101A20 !important" : "#E9EDEF !important", // Initial background color
              "&:hover": {
                backgroundColor: AppTheme === "dark" ? "#101A20 !important" : "#E9EDEF !important", // Ensure hover background stays the same
              },
              "&:active": {
                backgroundColor: AppTheme === "dark" ? "#101A20 !important" : "#E9EDEF !important", // Ensure active background stays the same

              },
              color: "#8696A0"
            }}
          >


            <CloseIcon />


          </IconButton>

           <Box sx={{
            color: AppTheme === "dark" ? "#B3BFC5 !important" : "#424C51",
            flex: "grow1",
            width: "calc(100% - 50px)",
            fontSize: "18px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // background:'red',
            whiteSpace: "nowrap",  // Ensure it stays on one line
            overflow: "hidden",    // Hide overflow text
            textOverflow: "ellipsis", // Add ellipsis
            // direction: i18n.language === "ar" && (isTypingInArabic(mediaFileInput.name)===true)? 'rtl' : 'ltr', 
            //   textAlign: i18n.language === "ar" && (isTypingInArabic(mediaFileInput.name)===true)? 'right' : 'left',
            //   textAlign:i18n.language === "ar"?'end':'start'
          }}>

<Typography
            variant="body1"
            sx={{
              width:"70%",
              maxWidth:"50%",
              fontSize: "18px",
              color: AppTheme === "dark" ? "#B3BFC5 !important" : "#424C51",
              whiteSpace: "nowrap",  // Ensure it stays on one line
              overflow: "hidden",    // Hide overflow text
              textOverflow: "ellipsis" 
            }}
          >
             {mediaFileInput.name}
          </Typography>

          
          </Box> 
        </Box> */}


        
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            position: 'absolute',
            bottom: {
              xs: '50px',   // Bottom position for extra-small screens
              sm: '50px',   // Bottom position for small screens
              md: '60px',   // Bottom position for medium screens
              lg: '60px',   // Bottom position for large screens
              xl: '80px'    // Bottom position for extra-large screens
            },
            zIndex:10000000
          }}
        >

          <IconButton
            onClick={() =>{ 
               
                if(!isFileSend){
                  setIsFileSend(true)
                  handleSend(fileType,mediaFileInput,null)
                }
              
              
            }}
            sx={{
              color: "#ffffff !important",
              marginLeft: "30px",
              // position: 'absolute',
              // bottom: '40px',
              // right: "50px",
              background: "#00A884",
              padding: "16px", 
              fontSize: "24px", 
              borderRadius: "50%", 
              "&:hover": {
                pointer:'cursor',
                background: "#00795c", // Change background color on hover
                color: "#ffffff !important", // Ensures the text/icon color stays white on hover
              },
            }}
          >
            <SendOutlinedIcon sx={{ fontSize: "inherit", }} /> {/* Inherit size from IconButton */}
          </IconButton>
        </Box>


        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            position: 'absolute',
            top:'10px',
            fontSize: "18px",
            color: AppTheme === "dark" ? "#B3BFC5 !important" : "#424C51",
            whiteSpace: "nowrap",  // Ensure it stays on one line
            overflow: "hidden",    // Hide overflow text
            textOverflow: "ellipsis" ,
          }}
        >
{/* 
<Typography
            variant="body1"
            sx={{
              width:"70%",
              maxWidth:"50%",
              fontSize: "18px",
              color: AppTheme === "dark" ? "#B3BFC5 !important" : "#424C51",
              whiteSpace: "nowrap",  // Ensure it stays on one line
              overflow: "hidden",    // Hide overflow text
              textOverflow: "ellipsis" ,
              marginBottom:"20px"
            }}
          > 
             {mediaFileInput.name} Lorem ipsum dolor sit, amet consectetur adipisicing elit. Magnam aliquam corporis amet omnis ipsum. At eligendi dicta, ad sequi nesciunt quibusdam perspiciatis placeat provident atque iusto natus facere aspernatur molestiae. Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptate, dolorum inventore iure repudiandae minima sequi eum repellat nemo. Tenetur quasi soluta aliquid repudiandae architecto qui impedit voluptas, optio praesentium pariatur?
          {/* </Typography> 
        </Box> */}
        <Box
  sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    position: "absolute",
    top: "25px",
  }}
>

{/* <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 16,
            display: "flex",
            width: "50px",
            backgroundColor: AppTheme === "dark" ? "#101A20 !important" : "#E9EDEF !important", // Initial background color
            "&:hover": {
              backgroundColor: AppTheme === "dark" ? "#101A20 !important" : "#E9EDEF !important",
            },
            "&:active": {
              backgroundColor: AppTheme === "dark" ? "#101A20 !important" : "#E9EDEF !important",
            },
          }}
        > */}
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              top: '-7px',
              left: 16,
              backgroundColor: AppTheme === "dark" ? "#101A20 !important" : "#E9EDEF !important", // Initial background color
              "&:hover": {
                backgroundColor: AppTheme === "dark" ? "#101A20 !important" : "#E9EDEF !important", // Ensure hover background stays the same
              },
              "&:active": {
                backgroundColor: AppTheme === "dark" ? "#101A20 !important" : "#E9EDEF !important", // Ensure active background stays the same

              },
              color: "#8696A0"
            }}
          >


            <CloseIcon />


          </IconButton>
        {/* </Box> */}
  <Typography
    sx={{
      direction: i18n.language === "ar"&& (isTypingInArabic(mediaFileInput.name) === true) ? 'rtl' : 'ltr',
        
      textAlign: i18n.language === "ar" && (isTypingInArabic(mediaFileInput.name) === true) ? 'right' : i18n.language === "ar"?'end':'start',
      fontSize: "18px",
      color: AppTheme === "dark" ? "#B3BFC5 !important" : "#424C51",
      whiteSpace: "nowrap",      // Prevent text wrapping
      overflow: "hidden",        // Hide overflow text
      textOverflow: "ellipsis",  // Show '...' for overflow text
      maxWidth: "70%",           // Restrict text width to 70%
      textAlign: "center",       // Center align text
    }}
  >
    {mediaFileInput.name}
  </Typography>
</Box>

      



        {/* Media container to center content */}
        {mediaType == 'Photo' && <Box
          sx={{
            position:'relative',
            top:"-70px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection:"column",
            height: "100%",
            maxHeight: "80%", // Limit height
            maxWidth: "80%", // Limit width
            overflow: "hidden", // Hide overflow
            marginTop:"80px"
          }}
        >
        
          <MediaPreview mediaFileInput={mediaFileInput} />
        </Box>}

        {mediaType == 'Document' && 
        <Box
          sx={{
            position:"relative",
            top:"-50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70%",
            maxHeight: "70%", // Limit height
            maxWidth: "70%", // Limit width
            overflow: "hidden", // Hide overflow
          }}
        >
          <Box sx={{ background: AppTheme === "dark" ? "#111B21" : "#F0F2F5", fontSize: "170px", borderRadius: "16px", padding: "20px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}><IoIosDocument />
            <Box sx={{ fontSize: "30px", borderRadius: "16px", padding: "20px", color: "#B3BFC5" }}>{t('no_preview')}</Box>
            <Box sx={{ fontSize: "20px", borderRadius: "16px", padding: "20px", color: "#B3BFC5" }}>{fileSize}</Box>
          </Box>


        </Box>}


      </Box>
      );

}

export default MediaFile;