import React from "react";

const MediaPreview = React.memo(
  ({ mediaFileInput }) => {
    const objectURL = React.useMemo(() => {
      return URL.createObjectURL(mediaFileInput);
    }, [mediaFileInput]);

    // Clean up the object URL when the component unmounts or mediaFileInput changes
    React.useEffect(() => {
      return () => {
        URL.revokeObjectURL(objectURL);
      };
    }, [objectURL]);

    return mediaFileInput?.type.startsWith("image/") ? (
      <img
        src={objectURL}
        alt="Media preview"
        style={{ maxHeight: "60%", maxWidth: "100%" }}
      />
    ) : mediaFileInput?.type.startsWith("video/") ? (
      <video
        src={objectURL}
        controls
        style={{ maxHeight: "60%", maxWidth: "100%" }}
      />
    ) : (
      <div>Unsupported media type</div>
    );
  },
  (prevProps, nextProps) => prevProps.mediaFileInput === nextProps.mediaFileInput
);

export default MediaPreview;