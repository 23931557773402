import React, { useState,useEffect } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemText,
  Avatar,
  Checkbox,
  Button
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import NewGroupPage from "./NewGroupPage";
import { LocalConvenienceStoreOutlined } from "@mui/icons-material";

const AddGroupMembersScreen = ({ onClose, AppTheme,socketRef,userId,groupSelected,setGroupUsers }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { t, i18n } = useTranslation();
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedUserName,setSelectedUserName] = useState(['You'])
  const [newGroupPage,setNewGroupPage] = useState(false);
  const [users,setUsers]=useState([])
 
  
  const handleCheckboxChange = (event,userName) => {
    const userId = event.target.value;
    
    // Check if the userId is already selected
    if (selectedUserIds.includes(userId)) {
      // If the user is already selected, remove them from the array
      setSelectedUserIds(selectedUserIds.filter((id) => id !== userId));
      setSelectedUserName(selectedUserName.filter((name)=> name != userName))
    } else {
      // Otherwise, add the user to the array
      setSelectedUserIds([...selectedUserIds, userId]);
      setSelectedUserName([...selectedUserName, userName]);
    }
    // selectedUserName
  };
  
  const showSelectedMember = ()=>{
    let sortedUser = selectedUserName.sort((a, b) => a.localeCompare(b))
    console.log("sortedUser: ", sortedUser);
    setGroupUsers(sortedUser)
    setSelectedUserName(['You'])
  }
  // const users = [
  //   { id: 1, name: "Tsnyrw" },
  //   { id: 2, name: "Xipihkj" },
  //   { id: 3, name: "Rnslz" },
  //   { id: 4, name: "Gtwnmcx" },
  //   { id: 5, name: "Lqzrhzh" },
  //   { id: 6, name: "Mxdwnnh" },
  //   { id: 7, name: "Mdfsi" },
  //   { id: 8, name: "Sojxfn" },
  //   { id: 9, name: "Azupt" },
  //   { id: 10, name: "Xhcnaac" },
  //   { id: 11, name: "Nash" },
  //   { id: 12, name: "Taylor" },
  //   { id: 13, name: "Oliver" },
  //   { id: 14, name: "Emma" },
  //   { id: 15, name: "Sophia" },
  //   { id: 16, name: "Jackson" },
  //   { id: 17, name: "Mia" },
  //   { id: 18, name: "Lucas" },
  //   { id: 19, name: "Charlotte" },
  //   { id: 20, name: "Liam" },
  //   { id: 21, name: "Emily" },
  //   { id: 22, name: "Noah" },
  //   { id: 23, name: "Ella" },
  //   { id: 24, name: "Henry" },
  //   { id: 25, name: "Aiden" },
  //   { id: 26, name: "Harper" },
  //   { id: 27, name: "Amelia" },
  //   { id: 28, name: "James" },
  //   { id: 29, name: "Avery" },
  //   { id: 30, name: "Isaac" },
  //   { id: 31, name: "Levi" },
  //   { id: 32, name: "Aubrey" },
  //   { id: 33, name: "Zoey" },
  //   { id: 34, name: "Matthew" },
  //   { id: 35, name: "Grace" },
  //   { id: 36, name: "Benjamin" },
  //   { id: 37, name: "Ella" },
  //   { id: 38, name: "Jacob" },
  //   { id: 39, name: "Victoria" },
  //   { id: 40, name: "David" },
  //   { id: 41, name: "Scarlett" },
  //   { id: 42, name: "Eleanor" },
  //   { id: 43, name: "Ethan" },
  //   { id: 44, name: "Madison" },
  //   { id: 45, name: "Alexander" },
  //   { id: 46, name: "Aurora" },
  //   { id: 47, name: "Logan" },
  //   { id: 48, name: "Riley" },
  //   { id: 49, name: "Sebastian" },
  //   { id: 50, name: "Layla" },
  //   { id: 51, name: "Caleb" },
  //   { id: 52, name: "Hannah" },
  //   { id: 53, name: "Joshua" },
  //   { id: 54, name: "Chloe" },
  //   { id: 55, name: "Landon" },
  //   { id: 56, name: "Aria" },
  //   { id: 57, name: "Grayson" },
  //   { id: 58, name: "Nora" },
  //   { id: 59, name: "Gabriel" },
  //   { id: 60, name: "Sofia" },
  //   { id: 61, name: "Julian" },
  //   { id: 62, name: "Natalie" },
  //   { id: 63, name: "Samuel" },
  //   { id: 64, name: "Leah" },
  //   { id: 65, name: "Daniel" },
  //   { id: 66, name: "Sadie" },
  //   { id: 67, name: "Ezra" },
  //   { id: 68, name: "Ruby" },
  //   { id: 69, name: "Jack" },
  //   { id: 70, name: "Lilly" },
  //   { id: 71, name: "Luke" },
  //   { id: 72, name: "Isabelle" },
  //   { id: 73, name: "Ryan" },
  //   { id: 74, name: "Violet" },
  //   { id: 75, name: "Lincoln" },
  //   { id: 76, name: "Savannah" },
  //   { id: 77, name: "Aaron" },
  //   { id: 78, name: "Bella" },
  //   { id: 79, name: "Nolan" },
  //   { id: 80, name: "Katherine" },
  //   { id: 81, name: "Elias" },
  //   { id: 82, name: "Stella" },
  //   { id: 83, name: "Isaiah" },
  //   { id: 84, name: "Alexa" },
  //   { id: 85, name: "Jordan" },
  //   { id: 86, name: "Lucy" },
  //   { id: 87, name: "Angel" },
  //   { id: 88, name: "Ariana" },
  //   { id: 89, name: "Greyson" },
  //   { id: 90, name: "Mila" },
  //   { id: 91, name: "Easton" },
  //   { id: 92, name: "Molly" },
  //   { id: 93, name: "Carson" },
  //   { id: 94, name: "Vivian" },
  //   { id: 95, name: "Wyatt" },
  //   { id: 96, name: "Eden" },
  //   { id: 97, name: "Miles" },
  //   { id: 98, name: "Ivy" },
  //   { id: 99, name: "Anthony" },
  //   { id: 100, name: "Jasmine" },
  //   { id: 101, name: "Axel" },
  //   { id: 102, name: "Camila" },
  //   { id: 103, name: "Weston" },
  //   { id: 104, name: "Claire" },
  //   { id: 105, name: "Damian" },
  //   { id: 106, name: "Alice" },
  //   { id: 107, name: "Vincent" },
  //   { id: 108, name: "Elena" },
  //   { id: 109, name: "Everett" },
  //   { id: 110, name: "Willow" },
  //   { id: 111, name: "Adam" },
  //   { id: 112, name: "Georgia" },
  //   { id: 113, name: "Xavier" },
  //   { id: 114, name: "Madeline" },
  //   { id: 115, name: "Declan" },
  //   { id: 116, name: "Faith" },
  //   { id: 117, name: "Maxwell" }
  // ];

  // Filter users based on the search term
  const filteredUsers = users?.filter((user) =>
    user.userName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleNewGroupOpen= ()=>{
    setNewGroupPage(true)
  }

  const handleNewGroupClose= ()=>{
    setNewGroupPage(false)
  }
 
 useEffect(()=>{
   if(socketRef.current){
    let socketdata={
      'appKey': "12345",
      'userId':userId   
    }
    socketRef.current.emit("listAllUsers",socketdata,(response) => {
      if (response.error) {
        console.error("Validation failed:", response.error);
      } else {
        console.log("User validated:", response);
      }    
    })
    socketRef.current.on('allUsersList',handleAllUsersList)
   }
   
   return () => {
    if(socketRef.current)
    socketRef.current.off("allUsersList");
    
  };
 },[])


  const handleAllUsersList = (data) => {
        console.log('Received allUsersList event data:', data?.userList);
       
          setUsers(data?.userList); // Set state if data.userList is an array
       
      };

      const isTypingInArabic = (text) => {
        if (text === "") return true;
        // Regex to detect Arabic characters including extended ranges
        const arabicRegex =
          /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB70-\uFBFF\uFE70-\uFEFF]/;
        return arabicRegex.test(text);
      };
 
  return (
    <>
    {!newGroupPage &&<Box
      sx={{
        boxSizing: 'border-box',
        position: "absolute",
        padding: "15px 12px 12px 12px",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: AppTheme === "dark" ? "#111b21" : "#ffffff",
        
        transform: "translateX(100%)",
        animation: "slideIn 0.3s forwards",
      }}
    >
      {/* Close Button */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '16px', color: AppTheme === "dark" ? "#e9edef" : "#111B21", padding: '0 0 0 10px' }}>
          {t("addGroupMembers")}
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{ color: "#8696a0", padding: '8px' }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      {/* Search TextField */}
      <Box mt={2}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder={t("searchName")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            sx: {
              color: "blue",
              fontSize: "16px",
            },
          }}
          InputLabelProps={{
            sx: {
              color: "gray",
              fontSize: "14px",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              backgroundColor: AppTheme === "dark" ? "#202c33" : "#f0f2f5",
            },
            "& .MuiInputBase-input": {
              color: AppTheme === "dark" ? "#e9edef" : "#3b4a54",
              textAlign:
                      i18n.language === "ar" && isTypingInArabic(searchTerm)
                        ? "right"
                        : "left", // Align text based on language
                    direction:
                      i18n.language === "ar" && isTypingInArabic(searchTerm)
                        ? "rtl"
                        : "ltr",
            },
          }}
        />
      </Box>

      <Box
        mt={2}
        sx={{
          
          height: "calc(100vh - 200px)",
          maxHeight: "100vh",
          overflowY: "auto",
          paddingBottom: "0px",
          scrollbarWidth: "thin",
          scrollbarColor: AppTheme === "dark" ? "#3b4348 #111b21" : "#111b21",
        }}
      >
        {filteredUsers.length === 0 ? (
          <Typography
            align="center"
            sx={{
              mt: 2,
              color: AppTheme === "dark" ? "white" : "black",
              fontSize: "14px",
            }}
          >
            {t("userNotFound")}
          </Typography>
        ) : (
          filteredUsers.map((user) => (
            <ListItem key={user.userId} sx={{ padding: "10px", alignItems: "center",width:'100%' }}>
             <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          {/* Left Box for Avatar and Name */}
          <Box sx={{ display: 'flex', alignItems: 'center',  whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: "hidden",  }}>
            <Avatar sx={{  marginLeft: i18n.language === "ar" ? '10px' : '0', 
    marginRight: i18n.language === "ar" ? '0' : '10px' }}>{user.userName[0]}</Avatar>
            <ListItemText
              primary={user.userName}
              primaryTypographyProps={{
                sx: {
                  fontSize: '16px', // Set the font size here
                  color: AppTheme === "dark" ? "white" : "#111b21",
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: "hidden", 
                  direction: i18n.language === "ar" && (isTypingInArabic(user.userName)===true)? 'rtl' : 'ltr', 
                  textAlign: i18n.language === "ar" && (isTypingInArabic(user.userName)===true)? 'right' : 'left',
                },
              }}
            />
          </Box>
          {/* Right Box for Checkbox */}
          <Box>
            <Checkbox
              value={user.userId} // Set the value to the user's id (as a string)
              checked={selectedUserIds.includes(user.userId.toString())} // Ensure the comparison is done as strings
              onChange={(e)=>{
                handleCheckboxChange(e,user.userName)
              }} // Handle change event
            />
          </Box>
        </Box>
            </ListItem>
          ))
        )}
      </Box>
       {/* Done Button */}
       <Box
        sx={{
          display: "flex",
          justifyContent: "center",
                
          position: "fixed",
          bottom: 0,
          left: i18n.language === "ar" ? 0 : "auto", 
          right: i18n.language === "ar" ? "auto" : 0,
          width: "100%",
          padding: "15px",
          backgroundColor: AppTheme === "dark" ? "#111b21" : "#ffffff",
          boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.2)",
          zIndex: 1000,
        }}
      >
        <Button
        //   variant="contained"
          sx={{
            backgroundColor:'#00A884',
            color: "#ffffff",
            
            // padding: '8px 16px',   
            "&:hover": {
                backgroundColor: "#00A884",
              }, 
              borderRadius:'15px'
          }}
          onClick={() => {
            console.log("Selected User IDs:", selectedUserIds); // Log selected user IDs
            // Perform any other actions you want to take when clicking 'Done'
           handleNewGroupOpen()
            
          }}
          disabled={!selectedUserIds.length>0}
        >
          {t("done")}
        </Button>
      </Box>
    </Box>}
   {newGroupPage && <NewGroupPage onBack={handleNewGroupClose} AppTheme={AppTheme} selectedUserIds={selectedUserIds} socketRef={socketRef} userId={userId} groupSelected={groupSelected} showSelectedMember={showSelectedMember}/>}
    </>
  );
};

// Slide-in animation CSS
const slideInAnimation = `
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
`;

// Inject animation CSS into the page
const styleElement = document.createElement("style");
styleElement.textContent = slideInAnimation;
document.head.appendChild(styleElement);

export default AddGroupMembersScreen;
